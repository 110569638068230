<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <BackButton :handler="backToReports" />
      </v-col>
    </v-row>

    <div class="app mt-4">
      <div class="user-wrapper">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
        >
          <v-row>
            <v-col cols="12">
              <div class="" style="background-color: rgba(17, 42, 70, 0.04)">
                <p class="pa-4 font-extrabold text-xl text-blue">Filters</p>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <v-autocomplete
                v-model="searchParams.venueServiceId"
                outlined
                background-color="#fff"
                placeholder="Services"
                item-value="id"
                item-text="name"
                return-object
                clearable
                :items="venueServices"
                @change="getReport"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-select
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                background-color="#fff"
                placeholder="Type"
                v-model="searchParams.type"
                item-text="name"
                item-value="value"
                :items="[
                  { name: 'All', value: 'all' },
                  { name: 'Personal Trainer', value: 'trainer' },
                  { name: 'Academy', value: 'academy' },
                ]"
                @change="getReport"
                clearable
                class="q-autocomplete shadow-0"
                hide-details
                dense
              ></v-select>
            </v-col>
            <v-col md="2" v-if="searchParams.type != 'facility'">
              <v-select
                :items="trainers"
                placeholder="Trainer*"
                item-text="name"
                item-value="id"
                v-model="searchParams.trainerId"
                outlined
                @change="getReport"
                background-color="#fff"
                multiple
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
                <template
                  v-if="
                    searchParams.trainerId &&
                    searchParams.trainerId.length == trainers.length
                  "
                  v-slot:selection="{ index }"
                >
                  <span v-if="index == 0">All Trainers</span>
                </template>
                <template v-else v-slot:selection="{ item, index }">
                  <span v-if="index == 0">{{ item.name }}</span>
                  <span v-if="index == 1">, ...</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="2" style="padding-right: 0">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    background-color="#fff"
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                    v-model="date1Formatted"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.from_date"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
                class="q-text-field shadow-0"
                hide-details
                dense
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date2Formatted"
                    outlined
                    background-color="#fff"
                    readonly
                    v-on="on"
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.to_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-flex xs1 class="ml-1">
                <v-btn color="#00b0af" height="40" dark @click="getReport"
                  >Go</v-btn
                >
              </v-flex>
            </v-col>
          </v-row>

          <div class="md-card md-theme-default rounded-5 shadow-0">
            <div class="search_customer">
              <div class="header_title pl-4 pr-4">
                <v-row>
                  <v-col md="4">Trainer Performance Report</v-col>

                  <v-col md="4"> </v-col>

                  <v-col md="4" class="text-right">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="
                            checkExportPermission(
                              $modules.reports.discount.slug
                            )
                          "
                          class="export-button mt-2"
                          elevation="0"
                          height="40"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <SvgIcon text="Export Report">
                            <template v-slot:icon>
                              <ExportIcon />
                            </template>
                          </SvgIcon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="reportDownload('pdf')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as PDF</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="reportDownload('excel')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-excel-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as Excel</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div class="md-card-content">
              <div>
                <div
                  class="md-content md-table md-theme-default table_borders"
                  table-header-color="orange"
                  value
                >
                  <div
                    v-resize="onResize"
                    :style="{ height: `${containerHeight}px` }"
                  >
                    <table class="table table-striped header-fixed">
                      <thead class="md-card-header sticky shadow-0">
                        <tr>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled text-center"
                            >
                              <div class="md-table-head-label">Timestamp</div>
                            </div>
                          </th>
                          <th
                            class="md-table-head sticky"
                            v-if="searchParams.type != 'facility'"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled text-center"
                            >
                              <div class="md-table-head-label">
                                Trainer Name
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled text-center"
                            >
                              <div class="md-table-head-label">
                                Product Type
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label text-center">
                                Academy/Package Name
                              </div>
                            </div>
                          </th>

                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label text-center">
                                Start Time
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled text-center"
                            >
                              <div class="md-table-head-label">End Time</div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label text-center">
                                Duration (hr)
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled text-center"
                            >
                              <div class="md-table-head-label">
                                Customer Name
                              </div>
                            </div>
                          </th>

                          <th
                            class="md-table-head sticky"
                            v-if="searchParams.type != 'facility'"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled text-center"
                            >
                              <div class="md-table-head-label">
                                Trainer Revenue(Inc.VAT)
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled text-center"
                            >
                              <div class="md-table-head-label">Attendance</div>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody
                        v-if="attendanceReport.length > 0"
                        :set="
                          ((trainer_name = ''),
                          (record_type = ''),
                          (total_hours = 0),
                          (total_revenue = 0),
                          (grand_total_hours = 0),
                          (grand_total_revenue = 0))
                        "
                      >
                        <template v-for="(details, index) in attendanceReport">
                          <tr
                            :key="`datTotal_${index}`"
                            class="md-table-row font-weight-black grand_total_bg"
                            v-if="details.type == 'date' && index != 0"
                          >
                            <td></td>
                            <td
                              class="md-table-cell-container text-center font-weight-black"
                              :colspan="3"
                              :set="
                                (grand_total_hours += parseFloat(
                                  total_hours.toFixed(2)
                                ))
                              "
                            >
                              <div class="md-table-cell-container">
                                Total Hours of the Day:{{
                                  total_hours.toFixed(2)
                                }}
                                hr(s)
                              </div>
                            </td>
                            <td :colspan="3"></td>
                            <td
                              colspan="2"
                              class="md-table-cell-container text-center font-weight-black"
                              :set="
                                (grand_total_revenue += parseFloat(
                                  total_revenue.toFixed(2)
                                ))
                              "
                            >
                              Total Revenue of the Day:
                              {{ total_revenue.toFixed(2) }}
                            </td>
                            <td v-if="searchParams.type != 'facility'"></td>
                          </tr>
                          <span
                            v-if="details.type == 'date'"
                            :key="`obj_head_${index}`"
                            :set="((total_hours = 0), (total_revenue = 0))"
                          >
                          </span>
                          <tr
                            v-if="details.type == 'total'"
                            :key="`obj_head_${index}`"
                            class="grand_total_bg"
                            :set="((trainer_name = ''), (record_type = ''))"
                          >
                            <td></td>
                            <td
                              class="md-table-cell-container text-center font-weight-black"
                              :colspan="3"
                              :set="(total_hours += parseFloat(details.hours))"
                            >
                              <div class="md-table-cell-container">
                                Total Hours:{{ details.hours }} hr(s)
                              </div>
                            </td>
                            <td :colspan="3"></td>
                            <td
                              colspan="2"
                              class="md-table-cell-container text-center font-weight-black"
                              :set="
                                (total_revenue += parseFloat(
                                  details.trainer_revenue
                                ))
                              "
                            >
                              Total revenue:
                              {{ Number(details.trainer_revenue).toFixed(2) }}
                            </td>
                            <td v-if="searchParams.type != 'facility'"></td>
                          </tr>
                          <tr
                            v-else-if="details.type == 'data'"
                            :key="`obj_head_${index}`"
                          >
                            <td class="md-table-cell-container text-center">
                              <div class="md-table-cell-container text-center">
                                {{ details.date | dateformat }}
                              </div>
                            </td>
                            <td
                              class="md-table-cell"
                              v-if="
                                searchParams.type != 'facility' &&
                                details.trainer_name != trainer_name
                              "
                            >
                              <div
                                class="md-table-cell-container text-center"
                                :set="
                                  ((trainer_name = details.trainer_name),
                                  (record_type = ''))
                                "
                              >
                                {{ trainer_name }}
                              </div>
                            </td>
                            <td v-else></td>
                            <td
                              class="md-table-cell"
                              v-if="details.record_type != record_type"
                            >
                              <div
                                class="md-table-cell-container text-center"
                                :set="(record_type = details.record_type)"
                              >
                                {{ record_type }}
                              </div>
                            </td>
                            <td v-else></td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ details.name }}
                              </div>
                            </td>

                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ details.start_time }}
                              </div>
                            </td>

                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ details.end_time }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ details.hours || 0 }} hr(s)
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ details.customer_name }}
                              </div>
                            </td>

                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{
                                  details.trainer_revenue
                                    ? parseFloat(
                                        details.trainer_revenue
                                      ).toFixed(2)
                                    : 0 | toCurrency
                                }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ details.attendance }}
                              </div>
                            </td>
                          </tr>
                        </template>
                        <template>
                          <tr
                            :key="`datTotal_last_row`"
                            class="md-table-row font-weight-black grand_total_bg"
                          >
                            <td></td>
                            <td
                              class="md-table-cell-container text-center font-weight-black"
                              :colspan="3"
                              :set="
                                (grand_total_hours += parseFloat(
                                  total_hours.toFixed(2)
                                ))
                              "
                            >
                              <div class="md-table-cell-container">
                                Total Hours of the Day:{{
                                  total_hours.toFixed(2)
                                }}
                                hr(s)
                              </div>
                            </td>
                            <td :colspan="3"></td>
                            <td
                              colspan="2"
                              class="md-table-cell-container text-center font-weight-black"
                              :set="
                                (grand_total_revenue += parseFloat(
                                  total_revenue.toFixed(2)
                                ))
                              "
                            >
                              Total Revenue of the Day:
                              {{ total_revenue.toFixed(2) }}
                            </td>
                            <td v-if="searchParams.type != 'facility'"></td>
                          </tr>
                        </template>
                        <template>
                          <tr
                            :key="`grandTotal`"
                            class="md-table-row font-weight-black grand_total_bg sticky_bottom"
                          >
                            <td></td>
                            <td
                              class="md-table-cell-container text-center font-weight-black"
                              :colspan="3"
                            >
                              <div class="md-table-cell-container">
                                GRAND TOTAL :{{ grand_total_hours.toFixed(2) }}
                                hr(s)
                              </div>
                            </td>
                            <td :colspan="3"></td>
                            <td
                              colspan="2"
                              class="md-table-cell-container text-center font-weight-black"
                            >
                              GRAND TOTAL:
                              {{ currencyCode }}
                              {{ grand_total_revenue.toFixed(2) }}
                            </td>
                            <td v-if="searchParams.type != 'facility'"></td>
                          </tr>
                        </template>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td :colspan="9">
                            <v-card-text class="pa-8 text-center">
                              <h3>No data found!</h3>
                            </v-card-text>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import BackButton from "@/components/Common/BackButton.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";

export default {
  components: { ExportIcon, SvgIcon, BackButton },
  data() {
    return {
      attendanceReport: [],
      menu1: null,
      menu2: null,
      fileName: null,
      searchParams: {
        type: "all",
        from_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
        venueServiceId: null,
        facilityId: [],
        trainerId: null,
      },
      product_categories: [],
      products: [],
      containerHeight: 400,
      facilities: [],
      trainers: [],
    };
  },
  computed: {
    ...mapGetters({
      sportsCategory: "getSportsService",
    }),
    date1Formatted() {
      return moment(this.searchParams.from_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.to_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },

    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
  },
  mounted() {
    this.onResize();
    this.getTrainers();
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.searchParams.facilityId.length == this.facilities.length) {
          this.searchParams.facilityId = [];
        } else {
          this.searchParams.facilityId = this.facilities.map((item) => item.id);
        }
      });
      setTimeout(() => {
        this.getReport();
      });
    },
    icon() {
      if (this.searchParams.facilityId.length == this.facilities)
        return "mdi-close-box";
      if (this.searchParams.facilityId.length == 0)
        return "mdi-checkbox-blank-outline";
      return "mdi-minus-box";
    },
    backToReports() {
      this.$router.push({
        name: "Reports",
      });
    },
    getFilterUrlData() {
      if (
        moment(this.searchParams.from_date, "YYYY-MM-DD").isAfter(
          moment(this.searchParams.to_date, "YYYY-MM-DD")
        )
      ) {
        this.showError("To date must be greater than or equal to from date");
        return;
      }
      let url = `?type=${this.searchParams.type}&from_date=${this.searchParams.from_date}&to_date=${this.searchParams.to_date}`;
      this.fileName =
        this.searchParams.type.charAt(0).toUpperCase() +
        this.searchParams.type.slice(1) +
        "-" +
        this.searchParams.from_date +
        "-to-" +
        this.searchParams.to_date;
      let venueService = null;
      let Trainer = null;
      if (this.searchParams.venueServiceId) {
        url += `&venue_service_id=${this.searchParams.venueServiceId.venue_service_id}`;
        venueService = this.findValueByKey(
          this.venueServices,
          this.searchParams.venueServiceId.venue_service_id
        );
      }

      if (this.searchParams.trainerId) {
        url += `&trainer_ids=${this.searchParams.trainerId}`;
        Trainer = this.findValueByKey(
          this.trainers,
          this.searchParams.trainerId
        );
      }
      if (Trainer !== null) {
        let multiple = "";
        if (this.searchParams.trainerId.length > 1) {
          multiple = "...";
        }
        let trainerName = Trainer.name + multiple;
        this.fileName += "-" + trainerName.replace(/\s/g, "");
      }

      if (venueService !== null)
        this.fileName += "-" + venueService.name.replace(/\s/g, "");
      if (this.fileName != null)
        this.fileName = "Trainer-Performance-Report-" + this.fileName;
      else this.fileName = "Trainer-Performance-Report";
      return url;
    },
    findValueByKey(opts, key) {
      var match = opts.filter(function (opt) {
        if (Array.isArray(key) == true) {
          return opt.id === key[0];
        } else {
          return opt.id === key;
        }
      });
      return match[0] ? match[0] : null;
    },
    getTrainers() {
      this.$http
        .get(`venues/trainers/short`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.trainers = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getReport() {
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Loading");
      this.$http
        .get(`venues/reports/trainer-performance-report${url}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            const customerAttendance = data;
            this.attendanceReport = [];
            let hours = 0;
            let trainerRevenue = 0;
            let hourChecker = null;
            customerAttendance.forEach((log, index) => {
              if (index == 0) {
                this.attendanceReport.push({
                  date: log.date,
                  type: "date",
                });
              }
              if (
                index > 0 &&
                customerAttendance[index - 1] &&
                (customerAttendance[index - 1].trainer_name !=
                  customerAttendance[index].trainer_name ||
                  customerAttendance[index - 1].type !=
                    customerAttendance[index].type ||
                  customerAttendance[index - 1].date !=
                    customerAttendance[index].date)
              ) {
                this.attendanceReport.push({
                  hours: hours.toFixed(2),
                  trainer_revenue: trainerRevenue.toFixed(2),
                  type: "total",
                });
                hours = 0;
                trainerRevenue = 0;
              }
              if (
                index > 0 &&
                customerAttendance[index - 1] &&
                customerAttendance[index - 1].date !=
                  customerAttendance[index].date
              ) {
                this.attendanceReport.push({
                  date: log.date,
                  type: "date",
                });
              }
              if (hourChecker != log.checker) {
                hours = hours + parseFloat(log.hours);
                hourChecker = log.checker;
              }

              if (log.attendance != "Unmarked") {
                trainerRevenue =
                  trainerRevenue + parseFloat(log.trainer_revenue);
              }
              this.attendanceReport.push({
                start_time: log.start_time,
                end_time: log.end_time,
                hours: log.hours,
                attendance: log.attendance,
                scheduled_sessions: log.scheduled_sessions,
                trainer_name: log.trainer_name,
                customer_name: log.customer_name,
                trainer_revenue: log.trainer_revenue,
                service_name: log.service_name,
                facility_name: log.facility_name,
                name: log.name,
                type: "data",
                record_type: log.type,
              });
            });
            if (data.length > 0) {
              this.attendanceReport.push({
                hours: hours.toFixed(2),
                trainer_revenue: trainerRevenue.toFixed(2),
                type: "total",
              });
            }
          }
          this.hideLoader();
        })
        .catch((error) => {
          this.errorChecker(error);
          this.hideLoader();
        });
    },
    reportDownload(report_type) {
      let link = "";
      if (report_type == "pdf") {
        link = "trainer-performance-report-pdf";
      } else if (report_type == "excel") {
        link = "trainer-performance-report";
      }
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Downloading report");
      this.$http
        .get(`venues/reports/` + link + `/download${url}`, {
          responseType: "blob",
        })
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.downloadReport(response, this.fileName, report_type);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    onResize() {
      this.containerHeight = window.innerHeight - 380;
    },
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #edf7f8;
  z-index: 1;
  padding: 10px 4px;
}
.md-table table {
  overflow: scroll;
}
.md-card .md-card-header {
  z-index: 1000;
}
.orange-text {
  color: #ff9800 !important;
  caret-color: #ff9800 !important;
}
</style>
